import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Redirect from '../views/Redirect.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: Redirect
  }

]

const router = new VueRouter({
  // mode: 'history',
  routes
})

export default router
