<template>
  <div class="home" :theme="theme" :device="device.type" :part="part" :fast="fast_mode" :is_ios="$device.ios" :displayTargets="setup.displayTargets.length" :dragging="dragging">
      <dBG ref="bg"></dBG>
      <dLoading ref="loading"></dLoading>
      
      <!-- PC INSTRUCTIONS -->
      <dMsgBox ref="modal_practice_ins" modal="modal_practice_ins" v-if="device.type === 'pc'">
            <template slot="image"><img :src="assets.img.kid1"/></template>
            <template slot="content"><div v-html="data.project.prac_ins_msg"></div></template>
            <template slot="footer"><button class="btn btn-primary" @click="_initPracTrial" v-html="data.project.prac_ins_btn"></button></template>
      </dMsgBox>

      <dMsgBox ref="modal_main_ins" modal="modal_main_ins" v-if="device.type === 'pc'">
            <template slot="image"><img :src="assets.img.kid2"/></template>
            <template slot="content"><div v-html="data.project.main_ins_msg"></div></template>
            <template slot="footer">
                <button class="btn btn-primary" @click="_initMainTrial" v-html="data.project.main_ins_btn"></button>
            </template>
      </dMsgBox>

      <dMsgBox ref="modal_break" modal="modal_break" v-if="device.type === 'pc'">
            <template slot="image"><img :src="assets.img.kid2"/></template>
            <template slot="content"><div v-html="data.project.block_break_msg"></div></template>
            <template slot="footer"><button class="btn btn-primary" @click="_mainTrialContinue" v-html="data.project.block_break_btn"></button></template>
      </dMsgBox>

      <dMsgBox ref="modal_retrial" modal="modal_retrial" v-if="(device.type === 'pc') && (part === 'practice')">
            <template slot="image"><img :src="assets.img.kid3"/></template>
            <template slot="content"><div v-html="data.project.prac_ins_retrial"></div></template>
            <template slot="footer"><button class="btn btn-primary" @click="_retrialContinue" v-html="data.project.prac_ins_retrial_btn"></button></template>
      </dMsgBox>

      <dMsgBox ref="modal_retrial" modal="modal_retrial" v-if="(device.type === 'pc') && (part === 'main')">
            <template slot="image"><img :src="assets.img.kid3"/></template>
            <template slot="content"><div v-html="data.project.main_ins_retrial"></div></template>
            <template slot="footer"><button class="btn btn-primary" @click="_retrialContinue" v-html="data.project.main_ins_retrial_btn"></button></template>
      </dMsgBox>

      <dMsgBox ref="modal_screenout" modal="modal_screenout" v-if="device.type === 'pc'">
            <template slot="image"><img :src="assets.img.kid4"/></template>
            <template slot="content"><div v-html="data.project.ins_qc"></div></template>
            <template slot="footer"><button class="btn btn-primary" @click="_reload" v-html="data.project.ins_qc_btn1"></button> <button class="btn btn-primary" @click="_getOut" v-html="data.project.ins_qc_btn2"></button></template>
      </dMsgBox>
      <!-- END PC INSTRUCTIONS -->

      <!-- MOBILE INSTRUCTIONS -->
      <dMsgBox ref="modal_practice_ins" modal="modal_practice_ins" v-if="device.type === 'mobile'">
            <template slot="image"><img :src="assets.img.kid1"/></template>
            <template slot="content"><div v-html="data.project.m_prac_ins_msg"></div></template>
            <template slot="footer"><button class="btn btn-primary" @click="_initPracTrial" v-html="data.project.m_prac_ins_btn"></button></template>
      </dMsgBox>

      <dMsgBox ref="modal_main_ins" modal="modal_main_ins" v-if="device.type === 'mobile'">
            <template slot="image"><img :src="assets.img.kid2"/></template>
            <template slot="content"><div v-html="data.project.m_main_ins_msg"></div></template>
            <template slot="footer"><button class="btn btn-primary" @click="_initMainTrial" v-html="data.project.m_main_ins_btn"></button></template>
      </dMsgBox>

      <dMsgBox ref="modal_break" modal="modal_break" v-if="device.type === 'mobile'">
            <template slot="image"><img :src="assets.img.kid2"/></template>
            <template slot="content"><div v-html="data.project.m_block_break_msg"></div></template>
            <template slot="footer"><button class="btn btn-primary" @click="_mainTrialContinue" v-html="data.project.m_block_break_btn"></button></template>
      </dMsgBox>

      <dMsgBox ref="modal_retrial" modal="modal_retrial" v-if="(device.type === 'mobile') && (part === 'practice')">
            <template slot="image"><img :src="assets.img.kid3"/></template>
            <template slot="content"><div v-html="data.project.m_prac_ins_retrial"></div></template>
            <template slot="footer"><button class="btn btn-primary" @click="_retrialContinue" v-html="data.project.m_prac_ins_retrial_btn"></button></template>
      </dMsgBox>

      <dMsgBox ref="modal_retrial" modal="modal_retrial" v-if="(device.type === 'mobile') && (part === 'main')">
            <template slot="image"><img :src="assets.img.kid3"/></template>
            <template slot="content"><div v-html="data.project.m_main_ins_retrial"></div></template>
            <template slot="footer"><button class="btn btn-primary" @click="_retrialContinue" v-html="data.project.m_main_ins_retrial_btn"></button></template>
      </dMsgBox>

      <dMsgBox ref="modal_screenout" modal="modal_screenout" v-if="device.type === 'mobile'">
            <template slot="image"><img :src="assets.img.kid4"/></template>
            <template slot="content"><div v-html="data.project.m_ins_qc"></div></template>
            <template slot="footer"><button class="btn btn-primary" @click="_reload" v-html="data.project.m_ins_qc_btn1"></button> <button class="btn btn-primary" @click="_getOut" v-html="data.project.m_ins_qc_btn2"></button></template>
      </dMsgBox>
      <!-- END MOBILE INSTRUCTIONS -->

      <dBlock ref="block"></dBlock>
      <dGame ref="prac"></dGame>
      <dGame ref="main"></dGame>
      <dSave ref="save"></dSave>

      <div class="dMainTitle" v-if="title.show">{{title.content}}</div>
      <img src="@/assets/img/hint.png" style="display:none;">
      <dForceLandscape v-if="device.type==='mobile' && (data.project.auto_landscape)"></dForceLandscape>


  </div>
</template>



<script>
import $ from "jquery"
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.css';

import Api from '@/services/Api'
import { MIX_data } from '@/js/data'
import dBG from '@/components/dBG'
import dLoading from '@/components/dLoading'
import dMsgBox from '@/components/dMsgBox'
import dGame from '@/components/dGame'
import dBlock from '@/components/dBlock'
import dSave from '@/components/dSave'
import dForceLandscape from '@/components/dForceLandscape'

var mobile = require('is-mobile');
const { detect } = require('detect-browser');


export default {
  name: 'home',
  mixins: [MIX_data],
  components: {
    dBG,
    dLoading,
    dMsgBox,
    dGame,
    dBlock,
    dSave,
    dForceLandscape
  },
  data(){
    return {
      user : null,
      device : {
        type:'pc'
      },
      title : {
          show : false,
          content : '',
      },
      fast_mode : false,
      browser : null,
      theme : 1,
      part : "loading",
      dragging : false,
      preloads : {
         saving : require('@/assets/img/loading.gif'),
      },
      assets : {
         img : {
           kid1 : require('@/assets/img/kid1.png'),
           kid2 : require('@/assets/img/kid2.png'),
           kid3 : require('@/assets/img/kid3.png'),
           kid4 : require('@/assets/img/kid4.png'),
           hint : require('@/assets/img/hint.png'),
           box : require('@/assets/img/msg.png'),
         },
         others : [require("@/assets/img/introT.png"),require("@/assets/img/introB.png")],
      },
    }
  },
  mounted () {
        if(mobile()){
          this.device.type = "mobile"
        }
        this.browser = detect()
        this.user = this.$route.query    
        
        this._initProject().then(()=>{
            if(this.data.project.fast_mode){
                this.$refs.loading._start().then(()=>{})
                this.$refs.bg._open(true).then(()=>{
                    this._goPart("practice")
                })
            }else{
                this.$refs.loading._start().then(()=>{
                    this.$refs.bg._open(true).then(()=>{
                        this._goPart("practice")
                    })
                })
            }
        })

  },
  methods : {
      _goPart(type){
          switch(type){
             case "practice":
                    this.part = "practice"
                    if(this.data.project.prac_switch == "0"){
                        this._goPart("main")
                        return false
                    }
                    this.$refs.modal_practice_ins._show().then(()=>{
                        this.$refs.bg._play(false)
                    })
             break;
             case "main":
                    this.part = "main"
                    if(this.data.project.main_switch == "0"){
                        this._goPart("saving")
                        return false
                    }
                    this.$refs.modal_main_ins._show().then(()=>{
                          this.$refs.bg._play(false)
                    })
             break;
             case "saving":
                    this.part = "saving"
                    this.$refs.bg._open(false).then(()=>{
                          this._savingData()
                    })             
             break;
          }
          return true
      },
      _partEnd(){
          if(this.part === "practice"){
              this._goPart("main")             
          }else{
              this._goPart("saving")
          }
      },
      _initPracTrial(){
          this._fullscreen()
          this.$refs.modal_practice_ins._hide().then(()=>{
                this.$refs.prac._start()
          }) 
      },
      _initMainTrial(){
          this._fullscreen()
          this.$refs.modal_main_ins._hide().then(()=>{
                this.$refs.main._start()
          }) 
      },
      _mainTrialContinue(){
         this.$refs.modal_break._hide().then(()=>{
            this.$refs.main._continue()
         })
      },
      _retrialContinue(){
        this.$refs.modal_retrial._hide().then(()=>{
            if(this.part === "main"){
                this.$refs.main._continueRetrial()
            }else{
                this.$refs.prac._continueRetrial()
            }
         })       
      },
      _screenout(){
          this.$refs.modal_screenout._show().then(()=>{

          })
      },
      _reload(){
          this.$refs.modal_screenout._hide().then(()=>{
              location.reload()
          })
      },
      _getOut(){
          this.$refs.modal_screenout._hide().then(()=>{
              location.href = this.data.project.qc_url
          })         
      },
      _fullscreen(){
             if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen()
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen()
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
            }
            if (this.device.type == "mobile" && (this.data.project.auto_landscape)) {
                if (screen.orientation != undefined) {
                    screen.orientation.lock('landscape')
                }
            }         
      }

  }

}
</script>
