<template>
    <div class="DLoading" v-show="show" :class="{open:open}">
        <div class="bg"></div>
        <div class="body">
			<div class="progress" :data-percentage="percent">
				<span class="progress-left">
					<span class="progress-bar"></span>
				</span>
				<span class="progress-right">
					<span class="progress-bar"></span>
				</span>
				<!-- <div class="progress-value">
					<div>{{percent}}%<strong v-html="text.loading"></strong></div>
				</div> -->
                <div class="progress-logo">
                    <div><img :src="$parent.preloads.saving"></div>
                </div>
			</div>
            <div class="title">{{text.loading}} {{percent}}%</div>
        </div>
    </div>
</template>

<script>
import $ from "jquery"
let _ = require('lodash')

export default {
  name: 'dLoading',
  data(){
      return {
          show : false,
          open : false,
          percent : 0,
          preloads : [],
          loads : [],
          loaded : [],
          text : {
              title: '',
              loading: ''
          }
      }
  },
  watch: {
      show(){
          $(document).ready(()=>{
            $(window).trigger("resize")
          })
      }
  },
  mounted(){
      $(document).ready(()=>{
          $(window).on("resize",()=>{
              this._responsive()
          })
          $(window).trigger("resize")
      })
  },
  methods:{
        _setup(){
            return new Promise((resolve)=>{
                this.text.title = this.$parent.data.project.survey_title
                this.text.loading = this.$parent.data.project.load_txt

                _.forOwn(this.$parent.preloads, (v, k)=> { 
                    this.preloads.push(v)
                })
                _.forOwn(this.$parent.assets.img, (v, k)=> { 
                    this.loads.push(v)
                })
                this.$parent.assets.others.map((v,k)=>{
                    this.loads.push(v)
                })
                this.$parent.data.targets.map((v,k)=>{
                    if(v.target_img != null){
                        this.loads.push(v.target_img)
                    }
                })
                this.$parent.data.primes.map((v,k)=>{
                    if(v.prime_img != null){
                        this.loads.push(v.prime_img)
                    }
                })
                this.$parent.data.prac_primes.map((v,k)=>{
                    if(v.prime_img != null){
                        this.loads.push(v.prime_img)
                    }
                })
                resolve()
            })
        },
        _start(){
           return new Promise((resolve)=>{
               this.percent = 0
               this._setup().then(()=>{
                    this._preloadAssets().then(()=>{
                        if(this.$parent.data.project.fast_mode){
                            this._show(false)
                        }else{
                            this._show(true)
                        }
                        this._open(true).then(()=>{
                            this.$parent.$refs.bg._play(true)
                            this._loadAssets().then(()=>{
                                    this._open(false).then(()=>{
                                        this.$parent.$refs.bg._play(false)
                                        this._show(false)
                                        resolve()
                                    })                           
                            })
                        })
                    })
               })
           })
        },
        _preloadAssets(){
            return new Promise((resolve)=>{
                let all = []
                this.preloads.map((v)=>{
                    all.push(this._loadImg(v))
                })
                Promise.all(all).then(()=>{
                    resolve()
                })  
            })
        },
        _loadAssets(){
            return new Promise((resolve)=>{
                this.percent = 0
                this.loads.map((v)=>{
                    this._loadImg(v).then((loadedTarget)=>{
                        this.loaded.push(loadedTarget)
                        //this.percent = (this.loaded.length / this.loads.length) * 100
                        this.percent = Math.ceil((this.loaded.length / this.loads.length) * 10) * 10
                        if(this.loaded.length >= this.loads.length){
                            resolve()
                        }
                    })
                })
            })
        },
        _loadImg(target){
            return new Promise((resolve)=>{
                let img = new Image()
                img.onload = ()=>{
                    $(document.body).append("<img src='"+target+"' style='display:none;'/>")
                    resolve(target)
                }
                img.src = target
            })
        },
        _show(status){
            this.show = status
        },
        _open(status){
            return new Promise((resolve)=>{
                this.open = status
                setTimeout(()=>{
                    resolve()
                },500)  
            })
        },
        _responsive(){
                    // var page = $(window);
                    // var main = $(".DLoading > .body");
                    // var scale = 1;
                    // main.css({"transform":"translate(-50%,-50%) scale("+scale+")","transform-origin": "50% 50%"});
                    // while((main.get(0).getBoundingClientRect().height >= page.outerHeight()) || (main.get(0).getBoundingClientRect().width >= (page.outerWidth() / 2))){
                    //     scale -= .01;
                    //     main.css({"transform":"translate(-50%,-50%) scale("+scale+")","transform-origin": "50% 50%"});
                    // }
        }


  }
}

</script>