<template>
  <div class="redirect"></div>
</template>

<script>
export default {
  name: 'redirect',
  mounted(){
      let path = this.$route.query.path
      location.href = path
  }
}
</script>


