<template>
    <div class="dBlockCont" :class="{Close:(!show)}">{{content}}</div>
</template>

<script>
export default {
  name: 'dBlock',
  props: [],
  data(){
      return {
          show : false,
          content : '',
      }
  },
  watch: {

  },
  mounted(){

  },
  methods: {
      _show(status,content = null){
          return new Promise((resolve)=>{

            let timeIn = 500
            if(this.$parent.data.project.fast_mode){
                timeIn = 0
            }   


            if(status){
                this.content = content
                this.show = true
                setTimeout(()=>{
                    resolve()
                },timeIn)
            }else{
                this.show = false
                setTimeout(()=>{
                    this.content = null
                    resolve()
                },timeIn)               
            }
          })

      }
  }
}
</script>