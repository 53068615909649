<template>
    <div class="DBg" v-show="show" :class="{open:open,play:play}">
        <div class="body">
            <div class="item"></div>
            <div class="item"></div>
        </div>
     </div>
</template>

<script>
import $ from "jquery";
let _ = require('lodash');

export default {
  name: 'dBG',
  components: {
  },
  data(){
      return {
          show : false,
          open : false,
          play : false,
      }
  },
  mounted(){

  },
  methods : {
      _open(status){
          return new Promise((resolve)=>{
              this.open = status 
                setTimeout(()=>{
                    resolve()
                },1000)
          })
      },
      _play(status){
          this.play = status 
      }


  }
}

</script>