<template>
      <!-- <div class="dPrime" :tType="$parent.$parent.data.project.target_display" v-bind:animation="animation" v-if="show" :pID="prime.prime_id">
          <div class="body d__DragItem word ui-draggable ui-draggable-handle" v-if="prime.prime_type === 'word'" :style="{top:$parent.$parent.data.project.prime_vertical_align+'%'}">
            <div class="content" :fs="$parent.$parent.data.project.prime_fontsize" v-html="prime.prime_name"></div>
            <div class="hint" v-if="hint"><div><img :src="$parent.$parent.assets.img.hint"></div></div>
            <div class="hint2" v-if="((hint) && ($parent.$parent.data.project.target_display == 'circular'))" v-html="_computedHint2"></div>
          </div>
          <div class="body d__DragItem image ui-draggable ui-draggable-handle" v-if="prime.prime_type === 'image'" :style="{top:$parent.$parent.data.project.prime_vertical_align+'%'}">
            <div class="content"><img :src="prime.prime_img"></div>
            <div class="hint" v-if="hint"><div><img :src="$parent.$parent.assets.img.hint"></div></div>

            <div class="hint2" v-if="((hint) && ($parent.$parent.data.project.target_display == 'circular'))" v-html="_computedHint2"></div>

          </div>
          <div class="body d__DragItem mixed ui-draggable ui-draggable-handle" v-if="prime.prime_type === 'combination'" :style="{top:$parent.$parent.data.project.prime_vertical_align+'%'}">
            <div class="content">
                <div class="image"><img :src="prime.prime_img"></div>
                <div class="text" :fs="$parent.$parent.data.project.prime_fontsize" v-html="prime.prime_name"></div>
            </div>
            <div class="hint" v-if="hint"><div><img :src="$parent.$parent.assets.img.hint"></div></div>
            <div class="hint2" v-if="((hint) && ($parent.$parent.data.project.target_display == 'circular'))" v-html="_computedHint2"></div>
            
          </div>
      </div> -->
     <div v-if="primeCont">
        <div class="dPrimeV2 d__DragItem word" :class="{show:animation}" :pID="prime.prime_id" v-if="(show) && (prime.prime_type === 'word')"  >
            <div class="body">
                <div class="content" :fs="$parent.$parent.data.project.prime_fontsize" v-html="prime.prime_name"></div>
            </div>
        </div>
        <div class="dHintMsg" v-if="(hint)" v-html="_computedHint2"></div>
      </div>
</template>



<script>
import $ from "jquery";
require('@/js/mobile-touch');
let _ = require('lodash');


export default {
  name: 'dPrime',
  components: {
  },
  data(){
      return {
          primeCont:false,
          show:false,
          animation:false,
          dropped:false,
          hint:false,
          hintTimer:null,
          prime : null,
          timeIn : null,
          firtrun : true,
          retrial : null,
      }
  },
  computed : {
      _computedHint2(){
          let proj = this.$parent.$parent.data.project
          let device = this.$parent.$parent.device.type
          let part = this.$parent.$parent.part
          if(part === "main"){
            if(device == "mobile"){
                return proj.m_main_hint_text
            }else{
                return proj.main_hint_text
            }
          }else{
               if(device == "mobile"){
                return proj.m_prac_hint_text
            }else{
                return proj.prac_hint_text
            }           
          }
      }
  },
  watch: {
      show(){
          $(document).ready(()=>{
            $(window).trigger("resize")
          })
      }
  },
  mounted(){
      $(document).ready(()=>{
          $(window).on("resize",()=>{
              this._responsive()
          })
          $(window).trigger("resize")
      })
  },
  methods : {
      _initPrimeCont(type){
          this.primeCont = type
      },
      _initPrime(prime){
          this.prime = prime
          return new Promise((resolve)=>{
              this.$parent.$parent.setup.displayTargets = this.$parent.$parent._copyObj(prime.targets)
                this.$parent._show().then(()=>{
                    
                        this._show().then(()=>{
                            this._hint()
                            this._start().then(()=>{
                                this._reset()
                                //setTimeout(()=>{
                                    resolve()
                                //},500)
                                
                            })
                        })
                   
                })
                
          })
      },
      _first_trial_delay(){
            let proj = this.$parent.$parent.data.project
            let part = this.$parent.$parent.part
            let delay = 0
            if(part === "main"){
                delay = proj.main_first_trial_delay
            }else{
                delay = proj.prac_first_trial_delay
            }
            if(this.$parent.record.final.length > 0){
                delay = 0
            }
            return new Promise((resolve)=>{
                setTimeout(()=>{
                    resolve()
                },delay)
            })
      },
      _start(){
           return new Promise((resolve)=>{
                    $(()=> {
                        this.timeIn = new Date().getTime()
                        this.dropped = false
                        var $target = $( ".d__DropItem" )
                        var oldPos = {
                            top : 0,
                            left : 0
                        }
                        this._retrialTimer(true)
                        $( ".d__DragItem").droppable( {
                            accept: ".d__DropItem",
                            drop:(event, ui)=>{
                                let sel = parseInt(ui.draggable.attr("tID"))
                                let targets = this.$parent.$parent._copyObj(this.$parent.$parent.setup.targets)
                                let selected = _.find(targets,{target_id:sel})
                                this.prime.rt = new Date().getTime() - this.timeIn
                                this.prime.choice = selected
                                this.dropped = true
                                this._hint(false)
                                this._retrialTimer(false)
                                
                                $(ui.draggable).css({transform:"translate(-50%,-50%) scale(0)",transition:'all .5s'})
                                $(".d__DropItem").draggable( 'disable' )
                                $(".d__DropItem:not([tID='"+sel+"'])").css({opacity:0,filter:"blur(1vmin)",transition:'all 1s'})
                                setTimeout(()=>{
                                    this.$parent.$refs['target'].show = false
                                    this.$parent.$parent.dragging = false
                                    resolve()
                                },1000)
                            }
                        });
                        $( ".d__DropItem" ).draggable({
                            cursorAt: { 
                                left: $target.get(0).clientWidth / 2,
                                top: $target.get(0).clientHeight / 2
                            },
                            drag:(event, ui)=>{
                                this._hint(false)
                                this.$parent.$parent.dragging = true
                            },
                            start:function(event, ui){
                                
                                //$(this).css({transform: "scale(.8)"});
                                oldPos.top = $(event.target).css('top')
                                oldPos.left = $(event.target).css('left')
                                $(this).draggable("option", "cursorAt", {
                                    left: this.clientWidth / 2,
                                    top: this.clientHeight / 2
                                });
                            },
                            stop:(event, ui)=>{
                                
                                if(this.dropped){
                                    return false
                                }
                                this._hint()
                                $(event.target).css({top:"50%",left:"50%"})

                            }
                        })
                    })
            })
      },
      _hint(start = true){
          if(!start){
              this.hint = false
              clearTimeout(this.hintTimer)
              return false
          }
          this.hintTimer = setTimeout(()=>{
              this.hint = true
          },1500)
      },
      _retrialTimer(start){
          if(!this.$parent.retrial.enable){
              return true
          }
          if(start){
            this.retial = setTimeout(()=>{
                $( ".d__DragItem").droppable( 'disable' )
                $( ".d__DropItem").draggable( 'disable' )
                this._reset()
                this._hint(false)
                this.$parent._showRetrial()
                this.$parent.$refs['target'].show = false
            },this.$parent.retrial.time)
          }else{
            clearTimeout(this.retial)
          }
      },
      _show(){
          return new Promise((resolve)=>{
              this.show = true
            //   resolve()
              //this.animation = false
              setTimeout(()=>{
                this.animation = true
                setTimeout(()=>{
                    this._first_trial_delay().then(()=>{
                        this.$parent.$refs['target'].show = true
                        setTimeout(()=>{
                            resolve()
                        },500)
                    })
                },500)
              },0)
          })
      },
      _hide(){
         return new Promise((resolve)=>{
            // this.show = false
            // this.animation = false
            resolve()
         })
      },
      _reset(){
          this._hide().then()
          this.dropped = false
          this.hint = false
          this.hintTimer = null
      },
      _responsive(){
            return false
            if(!this.show){
                return false
            }
            var page = $(window);
            var main = $(".dPrime .content");
            var scale = 1;
            main.css({"transform":"scale("+scale+")","transform-origin": "50% 50%"});
            while((main.get(0).getBoundingClientRect().height >= page.outerHeight()) || (main.get(0).getBoundingClientRect().width >= (page.outerWidth() / 1.5))){
                    scale -= .01;
                    main.css({"transform":"scale("+scale+")","transform-origin": "50% 50%"});
            }
            let $prime = $(".dPrime .body")
            try{
                $prime.draggable("option", "cursorAt", {
                    left: Math.floor($prime.get(0).clientWidth / 2),
                    top: Math.floor($prime.get(0).clientHeight / 2)
                })
            }catch(e){
                
            }
      }


  }
}

</script>