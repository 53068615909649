<template>
<div class="dGroup">
    <!-- <div class="DMainTargets" v-if="show">
        <div class="body">
            <div class="header" :class="{Open:(header.animate === 'open'),Close:(header.animate === 'close')}" v-html="$parent.data.project.header_txt"></div>
            <div class="items">
                <div class="item" v-for="(target, key) in $parent.setup.displayTargets" :key="key" :style="{width:$parent.setup.targetSize+'%'}" :tID="target.target_id" :class="{Open:(targets.animate === 'open'),Close:(targets.animate === 'close')}">
                    <div class="body">
                        <div class="content">
                            <div class="target image" v-if="(target.target_img)"><img :src="target.target_img"/></div>
                            <div class="target word" :fs="$parent.data.project.target_fontsize" v-else v-html="target.target_name"></div>
                            <div class="select" v-html="$parent.data.project.selected"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <dTargetCircle ref="target" v-if="$parent.data.project.target_display === 'circular'"></dTargetCircle>
    <dPrime ref="prime"></dPrime>
</div>
</template>

<script>
import $ from "jquery";
let _ = require('lodash');
import dPrime from '@/components/dPrime'
import dTargetBox from '@/components/targets/dTargetBox'
import dTargetCircle from '@/components/targets/dTargetCircle'

export default {
  name: 'dGame',
  components: {
      dPrime,
      dTargetBox,
      dTargetCircle
  },
  data(){
      return {
          type: 'circular',
          show : false,
          targets : {
              animate : null,
              list : [],
          },
          header : {
              animate : null
          },
          blocks : {
              current : 0,
              size : 0,
              restOn : 0,
              restType : null,
              breaks : 0,
          },
          blockDisplay : {
              current : 0,
              size : 0,
              text : null,
          },
          retrial : {
              enable : false,
              time : 2000,
              limit : 3,
              count : 0,
          },
          record : {temp:[],final:[]}
      }
  },
  mounted(){
      $(document).ready(()=>{
          $(window).on("resize",()=>{
              //this._responsive()
          })
          $(window).trigger("resize")
      })
  },
  methods : {
      _start(){
        this.$refs['prime']._initPrimeCont(true)
        this._initGame().then(()=>{
            this.$parent.$refs.block._show(true,this.blockDisplay.text).then(()=>{
                this.$parent.$refs.bg._play(true)
                    this._next()
            })
        })
      },
      _show(){
          return new Promise((resolve)=>{
              if(this.show){
                  resolve()
                  return false
              }
              this.show = true
              this.header.animate = "open"
              let timeIns = [300,1000]
                if(this.$parent.data.project.fast_mode){
                    timeIns = [0,0]
                }             

                setTimeout(()=>{
                    this.targets.animate = "open"
                },timeIns[0])
                setTimeout(()=>{
                        resolve()
                },timeIns[1])
          })
      },
      _hide(){
          return new Promise((resolve)=>{
              this.targets.animate = "close"
              let timeIns = [700,1000]
                if(this.$parent.data.project.fast_mode){
                    timeIns = [0,0]
                }    
                setTimeout(()=>{
                   this.header.animate = "close"
                },timeIns[0])
              setTimeout(()=>{
                    this.targets.animate = null
                    this.header.animate = null  
                    this.show = false
                    resolve()
              },timeIns[1])
          })
      },
      _initGame(){
          return new Promise((resolve)=>{
              if(this.$parent.part === "main"){  
                    this.targets.list = this._setupFinalPrimes(this.$parent._copyObj(this.$parent.data.primes))
              }else{
                    this.targets.list = this._setupFinalPrimes(this.$parent._copyObj(this.$parent.data.prac_primes))
              }
              this.record.temp = []
              this.record.final = []
              this._setRetrial()
              this._initBlocks().then(()=>{
                this._initBlockDisplay().then(()=>{
                    this._schedule().then(()=>{
                            resolve()
                    })
                })
              })
          })
      },
      _setupFinalPrimes(primes){
         let proj = this.$parent._copyObj(this.$parent.data.project)
         //let targets = this.$parent.data.targets
         let targets = this.$parent._copyObj(this.$parent.setup.targets)
         let list = []
          if(!proj.pairwise){
                primes.map((prime)=>{
                    let temp = this.$parent._copyObj(prime)
                    temp['targets'] = targets
                    list.push(temp)
                })
          }else{
              let collections = []
              this.$parent._copyObj(targets).map((target1)=>{
                this.$parent._copyObj(targets).map((target2)=>{
                    if(target1.target_id !== target2.target_id){
                        primes.map((prime)=>{
                            let pass = true
                            collections.map((pair)=>{
                               if((pair === target1.target_id+","+target2.target_id+",p"+prime.prime_id) || (pair === target2.target_id+","+target1.target_id+",p"+prime.prime_id)){
                                   pass = false
                               }
                            })
                            if(pass){
                                let temp = this.$parent._copyObj(prime)
                                temp['targets'] = [target1,target2]
                                list.push(temp)
                                collections.push(target1.target_id+","+target2.target_id+",p"+prime.prime_id)
                            }
                        })
                    }
                })
              })
              if(this.$parent.part != "main"){  
                 list = [_.shuffle(list)[0]]
              }
          }
          //console.log(list)
          return list
      },
      _setRetrial(){
            let proj = this.$parent._copyObj(this.$parent.data.project)
            if(this.$parent.part === "main"){  
                this.retrial.enable = proj.retrial
                this.retrial.limit = proj.retrial_limit
                this.retrial.time = proj.retrial_time
                this.retrial.count = 0
            }else{
                this.retrial.enable = proj.prac_retrial
                this.retrial.limit = 0
                this.retrial.time = proj.prac_retrial_time
                this.retrial.count = 0
            }
      },
      _showRetrial(){
            this.$refs['prime']._initPrimeCont(false)
            this.$parent.$refs.block._show(false).then(()=>{
                this._hide().then(()=>{
                    this.retrial.count += 1
                    if((this.retrial.count > this.retrial.limit) && (this.$parent.part === "main")){//SCREENOUT
                        this.$parent._screenout()
                        return true
                    }
                    this.$parent.$refs.modal_retrial._show().then(()=>{})
                })
            })
      },
      _continueRetrial(){
            if(this.$parent.part === "practice"){//RETRY PRACTICE
                this._start()
                return true
            }
            //CONTINUE MAIN TRIAL
            this.$refs['prime']._initPrimeCont(true)
            this.record.temp = _.shuffle(this.record.temp)
            this.$parent.$refs.block._show(true,this.blockDisplay.text).then(()=>{
                this.$parent.$refs.bg._play(true)
                this._next()
            })
      },
      _initBlocks(){
          return new Promise((resolve)=>{
              let targets = this.$parent._copyObj(this.targets.list)
              if(this.$parent.part === "main"){  
                this.blocks.size = this.$parent.data.project.block_count
                this.blocks.break = this.$parent.data.project.block_break
              }else{
                 this.blocks.size = 1
                 this.blocks.break = 0               
              }
              
              this.blocks.restType = null
              this.blocks.restOn = Math.ceil(targets.length / (this.blocks.break + 1))
              this.blockDisplay.size = this.blocks.size * (this.blocks.break + 1)
              resolve()
          })
      },
      _initBlockDisplay(){
          return new Promise((resolve)=>{
               if((this.blocks.restType === "block") || (this.blocks.restType === null)){
                    this.blocks.current += 1
               }
               this.blockDisplay.current += 1
               if(this.$parent.part === "main"){ 
                    if(this.blockDisplay.size != 1){
                        this.blockDisplay.text = this.$parent.data.project.block_label+" "+this.blockDisplay.current+"/"+this.blockDisplay.size//STATIC
                    }else{
                        this.blockDisplay.text = this.$parent.data.project.block_label
                    }
               }else{
                 this.blockDisplay.text = this.$parent.data.project.block_prac_label
               }
               resolve()
          })
      },
      _schedule(){
          return new Promise((resolve)=>{
            if(this.blocks.restType === "break"){
                resolve()
                return false
            }

            this.record.temp = this.$parent._copyObj(this.targets.list)
            this.record.temp.map((v,k)=>{
                v['no'] = 0
                v['rt'] = ""
                v['block'] = this.blocks.current
                v['order'] = k + 1
                v['choice'] = null
            })
            this.record.temp = _.shuffle(this.record.temp)
            resolve()
          })
      },
      _next(){
          this.$refs.prime._initPrime(this.record.temp[0]).then(()=>{
              this._record().then(()=>{
                 this._next()
              })
              
          })
      },
      _record(){
          return new Promise((resolve)=>{
              let data = this.$parent._copyObj(this.record.temp[0])
              let no = this.record.final.length + 1
              data.no = no
              
              this.record.final.push(data)
              this.record.temp.shift()
              
              if(this.record.temp.length === 0){
                  this._done()
                  return false
              }
              if(this.record.temp.length % this.blocks.restOn === 0){
                  this.blocks.restType = "break"
                  this._rest()//REST ON BREAK
                  return false
              }
              resolve()
          })
      },
      _done(){
          this.$refs['prime']._initPrimeCont(false)
          if(this.blocks.current >= this.blocks.size){
                this.$parent.$refs.block._show(false).then(()=>{
                    this._hide().then(()=>{
                         this.$parent._partEnd()
                    })
                })              
          }else{
                this.blocks.restType = "block"
                this._rest()//REST ON BLOCK
          }
      },
      _rest(){
          this.$refs['prime']._initPrimeCont(false)
          this.$parent.$refs.block._show(false).then(()=>{
                this._hide().then(()=>{
                    this.$parent.$refs.modal_break._show().then(()=>{
                            this.$parent.$refs.bg._play(false)
                    })
                })
          })
      },
      _continue(){
          this.$refs['prime']._initPrimeCont(true)
          this._initBlockDisplay().then(()=>{
            this._schedule().then(()=>{
                 this._show().then(()=>{
                    this.$parent.$refs.block._show(true,this.blockDisplay.text).then(()=>{
                        this.blocks.restType = null
                        this.$parent.$refs.bg._play(true)
                        this._next()
                    })          
                 })
            })
          })
      },
      _responsive(){
          $(document).ready(()=>{
                let $parent = $(".DMainTargets:visible")
                let $targets = $parent.find(".items")
                let $header = $parent.find(".header")
                let $h = $parent.height() - $header.height()
                $targets.css({height:$h+'px'})
          })
      }
  }
}

</script>