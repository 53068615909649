<template>
      <div class="dSave" v-show="show" :class="{Show:(animate === 'show'),Hide:(animate === 'hide')}">
          <div class="body">
              <div class="image"><img src="@/assets/img/loading.gif"/></div>
              <div class="text" v-html="content"></div>
          </div>
      </div>
</template>

<script>
import $ from "jquery";
export default {
  name: 'dSave',
  data(){
      return {
          show:false,
          animate:null,
          content:''
      }
  },
  methods : {
      _show(status,content=null){
          return new Promise((resolve)=>{
              if(status){
                  this.show = true
                  this.animate = "show"
                  this.content = content
                  setTimeout(()=>{
                      resolve()
                  },600)
              }else{
                  this.animate = "hide"
                  setTimeout(()=>{
                      this.show = false
                      this.animate = null
                      resolve()
                  },600)
              }
          })
      }
  }
}
</script>
